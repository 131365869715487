import _ from 'lodash';
import { Page } from '../model/Page';
import { JsonSerializer } from './JsonSerializer';
import globalConfig from '../../globalconfiguration';
import { Asset } from '../model/Asset';
import { Tenant } from '../model/Tentant';
import { Appointment } from '../model/Appointment';
import Messages from '@/store/modules/alert';
import { Invoice, InvoiceCard } from '../model/Invoice';
import { userService } from './UserService';
import authModule from '@/store/modules/auth';
import { Series } from '../model/Series';
import { Message } from '../model/Message';
import { Holiday } from '../model/Holiday';

class APIAccess {
  public async syncInvoices(start: string, end: string): Promise<Message> {
    return this.genericCallWithResult<Message>(
      {
        start,
        end,
      },
      'POST',
      '/api/v1/invoice/sync'
    );
  }
  public async syncInvoice(id: string): Promise<Message> {
    return this.genericCallWithResult<Message>({}, 'POST', `/api/v1/invoice/sync/${id}`);
  }

  public async deleteInvoice(data: any): Promise<boolean> {
    return this.genericCall(data, 'DELETE', '/api/v1/invoice');
  }
  public async CreateAllInvoices(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/invoice/createall');
  }
  public async CreateInvoice(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/invoice/createsingle');
  }

  public async openPdfInNewTab(id: string): Promise<void> {
    const fullUrl = this.genericGetUrl(id, '/api/v1/invoice/pdf');
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        ...(await userService.getAuthHeader()),
        'Content-Type': 'application/pdf;charset=UTF-8',
      },
    });

    if (response.status !== 200) return;

    const _url = window.URL.createObjectURL(await response.blob());
    window.open(_url);
  }

  public async getInvoice(id: string): Promise<Invoice> {
    return this.genericGetCall(id, '/api/v1/invoice');
  }

  public async addNewAsset(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/asset');
  }
  public async updateAsset(data: any): Promise<boolean> {
    return this.genericCall(data, 'PUT', '/api/v1/asset');
  }
  public async deleteAsset(data: any): Promise<boolean> {
    return this.genericCall(data, 'DELETE', '/api/v1/asset');
  }

  public async getSeries(id: string): Promise<Series> {
    return this.genericGetCall(id, '/api/v1/serie');
  }
  public async updateSeries(data: any): Promise<boolean> {
    return this.genericCall(data, 'PUT', '/api/v1/serie');
  }
  public async addNewSeries(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/serie');
  }
  public async deleteSeries(data: any): Promise<boolean> {
    return this.genericCall(data, 'DELETE', '/api/v1/serie');
  }
  public async deleteOutliner(data: any): Promise<boolean> {
    return this.genericCall(data, 'DELETE', '/api/v1/serie/outliner');
  }
  public async addOutliner(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/serie/outliner');
  }

  public async syncTenants(): Promise<Message> {
    return this.genericCallWithResult<Message>({}, 'POST', '/api/v1/tenant/sync');
  }
  public async syncTenant(id: string): Promise<Message> {
    return this.genericCallWithResult<Message>({}, 'POST', `/api/v1/tenant/sync/${id}`);
  }
  public async addNewTenant(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/tenant');
  }
  public async updateTenant(data: any): Promise<boolean> {
    return this.genericCall(data, 'PUT', '/api/v1/tenant');
  }
  public async deleteTenant(data: any): Promise<boolean> {
    return this.genericCall(data, 'DELETE', '/api/v1/tenant');
  }

  public async fetchAllHolidays(start: string, end: string): Promise<Holiday[]> {
    const call = async (): Promise<Response> => {
      const fullUrl = new URL('/api/v1/holiday', globalConfig.APIUrl);
      fullUrl.searchParams.append('start', start);
      fullUrl.searchParams.append('end', end);

      return fetch(fullUrl.toString(), {
        method: 'GET',
        headers: {
          ...(await userService.getAuthHeader()),
          'Content-Type': 'application/json',
        },
      });
    };

    const promise = await call();
    if (promise.status === 200) {
      const jsonData = JsonSerializer.DeserializeAppointment(await promise.text());
      return jsonData as Holiday[];
    }

    Messages.showError(promise.statusText);
    throw new Error('Api Call failed');
  }

  public async deleteHoliday(data: any): Promise<boolean> {
    return this.genericCall(data, 'DELETE', '/api/v1/holiday');
  }

  public async addHoliday(data: any): Promise<boolean> {
    return this.genericCall(data, 'POST', '/api/v1/holiday');
  }

  private async genericCallWithResult<TValue>(
    data: any,
    type: string,
    url: string
  ): Promise<TValue> {
    const call = async (): Promise<Response> => {
      const fullUrl = new URL(url, globalConfig.APIUrl);

      return fetch(fullUrl.toString(), {
        method: type,
        headers: {
          ...(await userService.getAuthHeader()),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    const promise = await call();

    if (promise.status === 200) {
      const jsonData = JsonSerializer.DeserializeAppointment(await promise.text());
      return jsonData as TValue;
    }

    Messages.showError(await promise.text());
    throw new Error('Api Call failed');
  }

  private async genericCall(data: any, type: string, url: string): Promise<boolean> {
    const call = async (): Promise<Response> => {
      const fullUrl = new URL(url, globalConfig.APIUrl);

      return fetch(fullUrl.toString(), {
        method: type,
        headers: {
          ...(await userService.getAuthHeader()),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    };

    try {
      const promise = await call();
      if (promise.status === 200) return true;
      Messages.showError(promise.statusText);
      return false;
    } catch (e) {
      Messages.showError((e as Error).message);

      location.reload();

      return false;
    }
  }

  private async genericGetCall<TValue>(id: string, url: string): Promise<TValue> {
    const call = async (): Promise<Response> => {
      const fullUrl = new URL(url, globalConfig.APIUrl);
      fullUrl.searchParams.append('id', `${id}`);

      return fetch(fullUrl.toString(), {
        method: 'GET',
        headers: {
          ...(await userService.getAuthHeader()),
          'Content-Type': 'application/json',
        },
      });
    };

    const promise = await call();
    if (promise.status === 200) {
      const jsonData = JsonSerializer.DeserializeAppointment(await promise.text());
      return jsonData as TValue;
    }

    Messages.showError(promise.statusText);
    throw new Error('Api Call failed');
  }

  private genericGetUrl<TValue>(id: string, url: string): string {
    const fullUrl = new URL(url, globalConfig.APIUrl);
    fullUrl.searchParams.append('id', `${id}`);

    return fullUrl.toString();
  }

  public async fetchAppointments(id: string, start: string, end: string): Promise<Appointment[]> {
    const call = async (): Promise<Response> => {
      const url = new URL('/api/v1/appointment', globalConfig.APIUrl);
      url.searchParams.append('assetId', `${id}`);
      url.searchParams.append('start', `${start}`);
      url.searchParams.append('end', `${end}`);

      return fetch(url.toString(), {
        method: 'GET',
        headers: await userService.getAuthHeader(),
      });
    };

    const result = await call();
    if (!result.ok) throw new Error(await result.text());

    const jsonData = JsonSerializer.DeserializeAppointment(await result.text());
    return jsonData as Appointment[];
  }

  public async fetchAllTenants(): Promise<Tenant[]> {
    const tenants = await this.GetAllFromPage<Tenant>('/api/v1/tenant/page');
    return _(tenants)
      .orderBy(t => t.name)
      .value();
  }
  public async fetchAllAssets(): Promise<Asset[]> {
    const assets = await this.GetAllFromPage<Asset>('/api/v1/asset/page');
    return _(assets)
      .orderBy(t => t.name)
      .value();
  }

  public async fetchAllInvoices(start: string, end: string): Promise<InvoiceCard[]> {
    return this.GetAllFromPageWithRange<InvoiceCard>('/api/v1/invoice/page', start, end);
  }

  private async GetAllFromPageWithRange<TResult>(
    apiPath: string,
    start: string,
    end: string
  ): Promise<TResult[]> {
    const call = async (p: number): Promise<Response> => {
      const url = new URL(apiPath, globalConfig.APIUrl);
      url.searchParams.append('page', `${p}`);
      url.searchParams.append('size', '50');
      url.searchParams.append('start', start);
      url.searchParams.append('end', end);

      return await fetch(url.toString(), {
        method: 'GET',
        headers: await userService.getAuthHeader(),
      });
    };

    return this.GetAllFromPageFromCall<TResult>(call);
  }

  private async GetAllFromPage<TResult>(apiPath: string): Promise<TResult[]> {
    const call = async (p: number): Promise<Response> => {
      const url = new URL(apiPath, globalConfig.APIUrl);
      url.searchParams.append('page', `${p}`);
      url.searchParams.append('size', '50');

      return await fetch(url.toString(), {
        method: 'GET',
        headers: await userService.getAuthHeader(),
      });
    };

    return this.GetAllFromPageFromCall<TResult>(call);
  }

  private async GetAllFromPageFromCall<TResult>(
    call: (p: number) => Promise<Response>
  ): Promise<TResult[]> {
    let entities: TResult[] = [];

    let page = 0;
    let pageContainer: Page<TResult>;
    do {
      const result = await call(page++);
      if (!result.ok) throw new Error(await result.text());

      const jsonData = JsonSerializer.Deserialize(await result.text());
      pageContainer = jsonData as Page<TResult>;
      if (pageContainer && pageContainer.entities)
        entities = entities.concat(pageContainer.entities);
    } while (pageContainer.entities.length === pageContainer.size);

    return entities;
  }
}

export const API = new APIAccess();
