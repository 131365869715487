export enum SyncState {
  None = 'None',
  Outdated = 'Outdated',
  UpToDate = 'UpToDate',
}

const SyncStateColors = new Map<SyncState, string>([
  [SyncState.None, 'gray'],
  [SyncState.Outdated, '#D2042D'],
  [SyncState.UpToDate, '#42da36'],
]);

export function getSyncColorString(e: SyncState): string {
  let name = SyncStateColors.get(e);
  if (!name) name = SyncStateColors.get(SyncState[e]);
  if (!name) return 'gray';

  return name;
}
