import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AssetView from '../views/AssetView.vue';
import TenantView from '../views/TenantView.vue';
import BockingView from '../views/BockingView.vue';
import InvoiceView from '../views/InvoiceView.vue';
import HolidayView from '../views/HolidayView.vue';
import LoginView from '../views/LoginView.vue';
import auth from '@/store/modules/auth';

const publicPages = ['/login'];

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'bookings',
    component: BockingView,
  },
  {
    path: '/assets',
    name: 'assets',
    component: AssetView,
  },
  {
    path: '/login',
    name: 'login-view',
    component: LoginView,
  },
  {
    path: '/tenants',
    name: 'tenants',
    component: TenantView,
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoiceView,
  },
  {
    path: '/holidays',
    name: 'holidays',
    component: HolidayView,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !auth.loggedIn) return next('/login');

  next();
});

export default router;
