class GlobalConfig {
  public readonly IssuerUrl: string = 'https://management.schwimmsportfreunde.de';
  public readonly APIUrl: string = 'https://management.schwimmsportfreunde.de';

  // Local Dev
  //public readonly IssuerUrl: string = 'http://localhost:9000';
  //public readonly APIUrl: string = 'http://localhost:5212';

  public readonly Version: string = '0.13';
}

export default new GlobalConfig();
