import GlobalConfig from '../../globalconfiguration';
import { User } from '../model/User';
import Messages from '@/store/modules/alert';

class UserService {
  private localStorageName = 'user';

  // Create Auth Header
  public async getAuthHeader(): Promise<Record<'Authorization', string> | Record<string, never>> {
    try {
      if (this.needToRenewToken()) await this.renewToken();
    } catch {
      await new Promise(r => setTimeout(r, 3000));
      location.reload();
    }

    const storaged = localStorage.getItem(this.localStorageName);
    if (!storaged) return {};

    const user = JSON.parse(storaged);
    if (user && user.token) return { Authorization: 'Bearer ' + user.token };
    else return {};
  }

  public getCurrentUser(): User | null {
    const storaged = localStorage.getItem(this.localStorageName);
    if (!storaged) return null;

    const user = JSON.parse(storaged) as User;
    if (user && user.token && user.exp) return user;

    return null;
  }

  // Logout
  public logout(): void {
    localStorage.removeItem(this.localStorageName);
  }

  // Login and create token
  public async login(username: string, password: string): Promise<User> {
    const response = await fetch(GlobalConfig.IssuerUrl + '/api/security/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const token = await response.text();

    if (!response.ok) {
      if (response.status === 401) {
        this.logout();
        location.reload();
      }
      return Promise.reject('Falscher Benutzername oder Passwort');
    }

    const user: User = { name: username, exp: this.parseJwt(token)['exp'], token };
    localStorage.setItem(this.localStorageName, JSON.stringify(user));

    return user;
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  // renew a token, user must had been login once
  public async renewToken(): Promise<User> {
    const user = this.getCurrentUser();
    if (!user) throw new Error('Kein Benutzer angemeldet!');

    const response = await fetch(GlobalConfig.IssuerUrl + '/api/security/RenewToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: user.token }),
    });

    const newToken = await response.text();

    if (!response.ok) {
      if (response.status === 401) {
        this.logout();
        location.reload();
      }

      this.logout();
      return Promise.reject('Session abgelaufen');
    }

    console.log(this.parseJwt(newToken));

    const newUser: User = { name: user.name, exp: this.parseJwt(newToken)['exp'], token: newToken };
    localStorage.setItem(this.localStorageName, JSON.stringify(newUser));

    return user;
  }

  public needToRenewToken(): boolean {
    const user = this.getCurrentUser();
    if (!user) {
      Messages.showError('Kein Benutzer angemeldet!');

      throw new Error('Kein Benutzer angemeldet!');
    }

    const timeLeft = user.exp - Date.now() / 1000;
    if (timeLeft <= 0) {
      this.logout();
      Messages.showError('Session abgelaufen!');
      throw new Error('Session abgelaufen!');
    }

    return timeLeft < 5 * 60;
  }
}

export const userService = new UserService();
